import { authorisedRequest } from './authorisedRequest';
import { Record } from '../pages/contentManager/Services/Services';
import { PriceList } from '../pages/contentManager/Services/servicesConfigs';

export const getProviderTypes = () => {
    return authorisedRequest(`/provider-types`, 'GET');
}

export const createServiceType = (payload: Record) => {
    return authorisedRequest(`/service-types`, 'POST', payload);
}

export const updateServiceType = (serviceTypeId: string, updatedPayload: Record) => {
  return authorisedRequest(`/service-types/${serviceTypeId}`, 'PUT', updatedPayload);
}

export const updateProviderType = (providerTypeId: string, updatedPayload: Record) => {
  return authorisedRequest(`/provider-types/${providerTypeId}`, 'PUT', updatedPayload);
}

export const uploadServiceImage = (payload: any, currentPriceListType: string) => {
    return authorisedRequest(`/admin/documents/upload?type=${currentPriceListType === PriceList.Service ? 'SERVICE_TYPE' : 'PROVIDER_TYPE'}`, 'POST', payload, {'Content-Type': 'multipart/form-data'});
}

export const deleteServiceType = (serviceTypeId: string) => {
    return authorisedRequest(`/service-types/${serviceTypeId}`, 'DELETE');
}

export const createProviderType = (payload: Record) => {
    return authorisedRequest(`/provider-types`, 'POST', payload);
}

export const deleteProviderType = (serviceTypeId: string) => {
    return authorisedRequest(`/provider-types/${serviceTypeId}`, 'DELETE');
}

