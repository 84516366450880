import React, { useContext, useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Card,
    Descriptions, notification, Switch, Input, Collapse
} from 'antd';
import './respiteCare.css';
import view from '../../icons/view.svg';
import download from '../../icons/download.svg';
import { fetchRespiteCareCustomerDetail, updateRespiteCareCustomerData } from '../../api/customers';
import { isUserRoleMarketing } from '../../utils/helpers';
import { EuroOutlined } from '@ant-design/icons';
import { MainContext } from '../../App';
const { Paragraph } = Typography;

export const RespiteCare = ({data}: {data: any}) => {
    const id = data.customer.id
    const { userAuthData } = useContext(MainContext);
    const [switchState, setSwitchState] = useState(false);
    const [respiteCareCustomer, setRespiteCareCustomer] = useState<null | any>(null);
    const [respiteCareMaxLimit, setRespiteCareMaxLimit] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchRespiteCareCustomerDetail(id)
            .then((resp: any) => {
                if(resp.status === 'ERROR'){
                    notification.error({
                        message: 'Respite Care not found!',
                        duration: 2
                    })
                } else {
                    setRespiteCareCustomer(resp)
                    setSwitchState(resp.enabled)
                }
            })
    }, [])

    const handleSwitchChange = async (checked: boolean) => {
        setSwitchState(checked)
        const resp = await updateRespiteCareCustomerData(id, { enable: checked })
        if (resp.status === 'ERROR') {
            setSwitchState(!checked)
            notification.error({
                message: 'Respite Care not found!',
                duration: 2
            })
        }
    }

    const handleInputValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: maxLimit }= event.target;
        let numbers = /^[0-9]+$/;
        if ((maxLimit.match(numbers) || maxLimit === '') && (maxLimit === '0' || maxLimit.indexOf('0') !== 0)) {
            setRespiteCareMaxLimit(maxLimit)
        }
    }

    const handleSaveMaxLimit = async () => {
        setLoading(true);
        const resp = await updateRespiteCareCustomerData(id, { amount: +respiteCareMaxLimit })
        setLoading(false);
        if (resp.status === 'ERROR') {
            notification.error({
                message: 'Respite Care not found!',
                duration: 2
            })
        }
    }

    const handleViewInvoice =  () => {
        window.open( respiteCareCustomer?.reimbursement.pdfUrl, '_blank')
    }

    const handleDownloadInvoice = async (language: string) => {
        // const resp = await fetchOrderInvoice(data.id, language)
        // if (resp.status === 'ERROR') {
        //   notification.error({
        //     message: 'Something went wrong!',
        //     duration: 2
        //   })
        // } else {
        //   fetch(resp.url)
        //       .then((response) => (response.blob()))
        //       .then((blob: any) => {
        //         const url = window.URL.createObjectURL(
        //             new Blob([blob]),
        //         );
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute(
        //             'download',
        //             `invoice.pdf`,
        //         );
        //         document.body.appendChild(link);
        //         link.click();
        //         link?.parentNode?.removeChild(link);
        //       })
        //       .catch(() => {
        //         notification.error({
        //           message: 'Something went wrong!',
        //           duration: 2
        //         })
        //       })
        // }
    }

    return (
        <Card>
            <Descriptions size="small" column={1} style={{ marginTop: 20 }}>
                <Descriptions.Item>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Switch onChange={handleSwitchChange}
                                checked={switchState}
                                disabled={isUserRoleMarketing(userAuthData)}
                        />
                        <Paragraph style={{ margin: 0, marginLeft: 15}}>{'Respite Care Limit'}</Paragraph>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item>
                    <div>
                        <Paragraph style={{ margin: 0 }}>Max Limit</Paragraph>
                        <Input
                            onChange={handleInputValueChange}
                            value={respiteCareMaxLimit}
                            prefix={<EuroOutlined />}
                            disabled={isUserRoleMarketing(userAuthData) || !switchState}
                        />
                        {respiteCareMaxLimit &&
                            <Button
                                loading={loading}
                                onClick={handleSaveMaxLimit}
                                type='primary' style={{ marginTop: 10 }}
                                disabled={isUserRoleMarketing(userAuthData)}
                            >
                                Save
                            </Button>
                        }
                    </div>
                </Descriptions.Item>
            </Descriptions>
            {respiteCareCustomer &&
                <Descriptions size="small" column={11} layout='vertical' className='description'>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Name">
                        {respiteCareCustomer.reimbursement?.firstName}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Last Name">
                        {respiteCareCustomer.reimbursement?.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Birthday">
                        {respiteCareCustomer.reimbursement?.birthDate}
                    </Descriptions.Item>
                    <Descriptions.Item  labelStyle={{ fontWeight: 'bold' }} label="Email">
                        {respiteCareCustomer.reimbursement?.email}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Phone number">
                        {respiteCareCustomer.reimbursement?.phoneNumber}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Address">
                        {respiteCareCustomer.reimbursement?.address}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="House number">
                        {respiteCareCustomer.reimbursement?.houseNumber}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Zip Code">
                        {respiteCareCustomer.reimbursement?.zipCode}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="City">
                        {respiteCareCustomer.reimbursement?.city}
                    </Descriptions.Item>
                    <Descriptions.Item style={{ width: '8%' }}>
                        <Button className={'button'}>
                            <img src={view} style={{ marginRight: '8px' }} onClick={handleViewInvoice}
                                 alt={'ViewIcon'} />
                            View pdf
                        </Button>
                    </Descriptions.Item>
                    <Descriptions.Item style={{ width: '10%' }}>
                        <Button className={'button'}>
                            <img src={download} style={{ marginRight: '8px' }}
                                 onClick={() => handleDownloadInvoice('de')} alt={'DownloadIcon'} />
                            Download pdf
                        </Button>
                    </Descriptions.Item>
                </Descriptions>
            }
        </Card>
    )
}
