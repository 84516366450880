import React from 'react';
import { Layout } from 'antd';
import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { formatDate } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';

export const RespiteCare = () => {
  const history = useHistory();
  const url = '/admin/reimbursements'
  const configs = [
    {
      label: 'Option 1',
      params: {
        option: 1,
        removeIndex: [ 2, 3, 4],
      },
    },
    {
      label: 'Option 2',
      params: {
        option: 2,
        removeIndex: [ 2, 3, 4],
      },
    },
    {
      label: 'Option 3',
      params: {
        option: 3,
        removeIndex: [ 2, 3, 4],
      },
    },

  ]
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Care recipient name',
      dataIndex: 'recipientName',
    },
    {
      title: 'Phone number',
      dataIndex: 'city',
    },
    {
      title: 'Insurance company',
      dataIndex: 'city',
    },
    {
      title: 'Insurance number',
      dataIndex: 'city',
    },
    {
      title: 'Last PDF date',
      dataIndex: 'submittedAt',
      render(submittedAt: string) {
        return  submittedAt ? formatDate(submittedAt, 'DD-MM-YYYY HH:mm') : 'N/A';
      }
    },
  ];
  const handleRowClick = (id: string, configIndex: number, record: any, currentPage: number) => {
    return history.push(`/respiteCare/${record.ref}/${configIndex}/${currentPage}`);
  }

  return (
    <InnerLayout>
        <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
          <Pager
              url={url}
              columns={columns}
              configs={configs}
              rowClick={handleRowClick}
          />
        </Layout.Content>
    </InnerLayout>
  )
}
