import React from 'react';
import { Button, Image, Input, Typography, Spin, notification, Checkbox } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { defaultSubService, ErrorData, getErrorText, getUniqueKey, LoadingData, Record, SubService } from './Services';
import cloudUploadIcon from '../../../icons/cloudUploadIcon.png';
import closeIcon from '../../../icons/closeIcon.png';
import { PriceList } from './servicesConfigs';
import { uploadServiceImage } from '../../../api/servicePrices';

const { Text } = Typography;

interface NewEditTypeContentProps {
    modalData: Record;
    setModalData(data: Record): void;
    error: ErrorData;
    setError(error: ErrorData): void;
    type: string;
    setImageId(imageId: string): void;
    modalTitle: string;
    loading: LoadingData;
    setLoading(loading: LoadingData): void;
    providerTypesList: string[]
}

export const NewEditTypeContent: React.FC<NewEditTypeContentProps> = (
    {
        modalData,
        setModalData,
        error,
        setError,
        type,
        setImageId,
        loading,
        setLoading,
        providerTypesList
    }) => {

    const { priceError, serviceImageError, subServicesError } = error;
    const { imageLoading } = loading;

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const data = type === PriceList.Service ? { ...modalData, translationKey: value }:{ ...modalData, keyword: value }
        setModalData(data);
    }

    const handleServiceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const data = type === PriceList.Service ? { ...modalData, serviceName: value }:{ ...modalData, name: value }
        setModalData(data);
    }

    const handleDescriptionKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setModalData({ ...modalData, descriptionKeyword: value });
    }

    const handleServicePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: price } = event.target;
        let numbers = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
        if ((price.match(numbers) || price === '') && price !== '0') {
            setModalData({ ...modalData, price });
            setError({ ...error, priceError: false });
        }
    }

    const handleProviderTypeChange = (providerTypes: any[]) => {
        setModalData({ ...modalData, providerTypes})
    }

    const handleSubServiceParamsChange = (indexItem: number, name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setError({ ...error, subServicesError: false });
        const subServices = (modalData.subServices as SubService[]).map((item, index: number) => {
            if (indexItem === index) {
                return { ...item, [name]: value };
            }
            return item;
        })
        setModalData({ ...modalData, subServices });
    }

    const handleSubServiceAdd = () => {
        const subServices: SubService[] = modalData.subServices as SubService[];
        setModalData({
            ...modalData,
            subServices: [ ...subServices, { ...defaultSubService, uniqueKey: getUniqueKey() } ]
        });
    }

    const handleUploadImage = async (event: any) => {
        const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
        setError({ ...error, serviceImageError: false });
        const file = event.target.files[0];
        const fileType = file.type;
        if (!allowedExtension.includes(fileType)) {
            return setError({ ...error, serviceImageError: true });
        }
        const data = new FormData();
        data.append('file', file);
        setLoading({...loading, imageLoading: true});
        const response = await uploadServiceImage(data, type);
        setLoading({...loading, imageLoading: false});
        if (response.status === 'ERROR') {
            return notification.error({
                message: getErrorText(response.result.errorCode)
            })
        }
        const { amazonUrl, id } = response;
        const newData =  type === PriceList.Service ? { ...modalData, serviceImage: amazonUrl } : { ...modalData, providerTypeImage: amazonUrl }
        setModalData(newData);
        setImageId(id);
    }

    const handleImageDelete = () => {
        const data =  type === PriceList.Service ? { ...modalData, serviceImage: '' } : { ...modalData, providerTypeImage: '' }
        setModalData(data);
        setImageId('');
    }
    return (
        <div style={styles.serviceTypeContentContainer as React.CSSProperties}>
            <div style={styles.modalContentInputRow}>
                <Input
                    placeholder='Keyword'
                    value={modalData.translationKey || modalData.keyword}
                    onChange={handleKeywordChange}
                    style={styles.modalContentInput}
                />
                <Input
                    placeholder={type === PriceList.Service ? 'Service name' : 'Provider type name'}
                    value={modalData.serviceName || modalData.name || modalData?.type && `${modalData?.type[0]}${modalData?.type?.slice(1).toLowerCase()}`}
                    onChange={handleServiceNameChange}
                    style={styles.modalContentInput}
                />
            </div>
            <div style={styles.modalContentInputRow}>
                <Input
                    placeholder={type === PriceList.Service ? 'Service price' : 'Provider rate'}
                    value={modalData.price}
                    onChange={handleServicePriceChange}
                    style={styles.modalContentInput}
                    suffix={'€/Hour'}
                />
                {type !== PriceList.Service &&
                    <Input
                        placeholder={'Description keyword'}
                        value={modalData.descriptionKeyword}
                        onChange={handleDescriptionKeywordChange}
                        style={styles.modalContentInput}
                    />
                }
            </div>
            <div>
                {priceError &&
                <div style={styles.errorTextContainer}>
                    <Text style={styles.errorText}>Price can be €10-€100.</Text>
                </div>
                }
            </div>
            {type === PriceList.Service &&
                <Checkbox.Group onChange={handleProviderTypeChange} value={modalData.providerTypes}>
                    {
                        providerTypesList.map((item:any) => <Checkbox value={item.value} key={item.id} style={styles.checkbox}>{item.label}</Checkbox>)
                    }
                </Checkbox.Group>
            }
            {type === PriceList.Service &&
                <Button icon={<PlusCircleFilled style={styles.addIcon} />}
                    style={(modalData.subServices as SubService [])?.length < 10 ?
                        styles.addButton as React.CSSProperties : styles.addDisabledButton as React.CSSProperties
                    }
                    onClick={handleSubServiceAdd}
                    disabled={(modalData.subServices as SubService [])?.length >= 10}
                >
                  Add subservice
                </Button>}
            {type === PriceList.Service &&
            (modalData.subServices as SubService[]).map((item, index) => {
                    return (
                        <div style={styles.modalContentInputRow} key={item.uniqueKey || item.id}>
                            <Input
                                placeholder='Keyword'
                                value={item.translationKey}
                                onChange={handleSubServiceParamsChange(index, 'translationKey')}
                                style={styles.modalContentInput}
                            />
                            <Input
                                placeholder='Subservice'
                                value={item.serviceName}
                                onChange={handleSubServiceParamsChange(index, 'serviceName')}
                                style={styles.modalContentInput}
                            />
                        </div>
                    )
                })
            }
            {
                subServicesError &&
                <div style={styles.errorTextContainer}>
                    <Text style={styles.errorText}>The names and keywords of added subservices are required.</Text>
                </div>
            }
            <div>
                {
                    (!modalData.serviceImage && !modalData.providerTypeImage) ?
                        <>
                            <Spin spinning={imageLoading} size={'small'} style={styles.spin}>
                                <label
                                    style={styles.uploadIconWrapper as React.CSSProperties}
                                    htmlFor='file-upload'
                                >
                                    <Image
                                        src={cloudUploadIcon}
                                        style={styles.uploadIcon as React.CSSProperties}
                                        preview={false}
                                    />
                                    Attach image
                                </label>
                            </Spin>
                            <Input
                                id='file-upload'
                                type={'file'}
                                accept='image/jpeg, image/jpg, image/png'
                                onChange={handleUploadImage}
                                style={styles.inputUploader}
                            />
                        </> :
                        <div style={styles.uploadedImageWrapper as React.CSSProperties}>
                            <Image
                                src={type === PriceList.Service ? modalData.serviceImage : modalData.providerTypeImage}
                                alt={'service image'}
                                style={styles.uploadedImage as React.CSSProperties}
                                preview={false}
                            />
                            <Image
                                src={closeIcon}
                                onClick={handleImageDelete}
                                style={styles.closeIcon as React.CSSProperties}
                                preview={false}
                            />
                        </div>
                }
            </div>
            <div>
                {serviceImageError &&
                <div style={styles.errorTextContainer}>
                    <Text style={styles.errorText}>Type of attached image can be jpeg or png.</Text>
                </div>
                }
            </div>
        </div>
    )
}

const styles = {
    serviceTypeContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: 318,
    },
    modalContentInputRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    modalContentInput: {
        width: 231,
        maxWidth: 231,
    },
    addIcon: {
        width: 18,
        height: 18,
        fontSize: 18,
        color: '#1a90ff',
    },
    addButton: {
        display: 'flex',
        alignItems: 'center',
        width: 142,
        height: 26,
        padding: '4px 8px',
        marginBottom: 16,
        fontFamily: 'Roboto',
        fontSize: 14,
        border: 'none',
        borderRadius: 13,
        backgroundColor: '#f0f2f5',
        color: '#1a90ff',
    },
    addDisabledButton: {
        display: 'flex',
        alignItems: 'center',
        width: 142,
        height: 26,
        padding: '4px 8px',
        marginBottom: 16,
        fontFamily: 'Roboto',
        fontSize: 14,
        border: 'none',
        borderRadius: 13,
        backgroundColor: '#f0f2f5',
        color: '#1a90ff',
        pointerEvents: 'none',
        opacity: 0.7
    },
    inputUploader: {
        display: 'none',
    },
    uploadIcon: {
        width: 24,
        height: 24,
        marginBottom: 6,
        objectFit: 'contain',
    },
    uploadIconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 132,
        height: 132,
        padding: '25px 29px 61px',
        marginBottom: 16,
        fontFamily: 'Roboto',
        fontSize: 12,
        borderRadius: 2,
        border: 'dashed 1px #d9d9d9',
        backgroundColor: '#fff',
        color: '#1a90ff',
        cursor: 'pointer',
    },
    uploadedImageWrapper: {
        position: 'relative',
        marginTop: 8,
        marginBottom: 16,
    },
    uploadedImage: {
        width: 100,
        height: 60,
        border: 'solid 1px #d9d9d9',
    },
    closeIcon: {
        width: 16,
        height: 16,
        position: 'absolute',
        top: -41,
        left: -9,
        color: '#bfbfbf',
    },
    spin: {
       width: 132,
    },
    errorTextContainer: {
        display: 'flex',
        marginTop: -13,
        marginBottom: 16,
    },
    errorText: {
        color: '#ee7080'
    },
    checkbox: {
        marginBottom: 16,
        marginRight: 30,
        marginLeft: 0,
        minWidth: 120,
    }
}
