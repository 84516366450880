import React, { CSSProperties, useState } from 'react';
import { Layout, Avatar, Radio, Space, Rate, Typography, Tooltip, Card, Descriptions } from 'antd';
import { ArrowLeftOutlined, WarningFilled } from '@ant-design/icons'
import { useParams, Link } from 'react-router-dom';
import './provider.css';
import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { ProviderDetails } from '../../components/providerDetails/ProviderDetails';
import { formatDate, getHours } from '../../utils/helpers';
import { TransactionHistory } from '../../components/orderDetails/TransactionHistory';
import { authorisedRequest } from '../../api/authorisedRequest';
import { useProviderDetails } from '../../components/providerDetails/useProviderDetails';
import { ProviderDocuments } from '../../components/providerDetails/ProviderDocuments';
import { ProviderWorkingAreas } from '../../components/providerDetails/ProviderWorkingAreas';
import { REQUIRED_CITIZEN_DOCUMENTS, REQUIRED_NON_CITIZEN_DOCUMENTS } from '../../components/providerDetails/ProviderDocuments'
import premiumUser from '../../icons/premium.svg';
import selfRegisteredUser from '../../icons/selfRegisteredUser.svg';

// const isContain = (requiredDocTypes: any[], allDocs: any[]) => {
//   const allDocTypes = allDocs?.map((doc) => doc.type);
//   const indexArray = requiredDocTypes.map(doc => {
//     return allDocTypes?.indexOf(doc);
//   });
//   return indexArray.indexOf(-1) === -1;
// }

const isContain = (requiredDocTypes: any[], allDocTypes: any[]) => {
  // const allDocTypes = allDocs?.map((doc) => doc.type);
  return requiredDocTypes?.every(requiredDocType => {
    return allDocTypes?.find((docType: any) => docType.type === requiredDocType)?.documents?.length;
  });

}
export const ordersUrl = `/admin/orders`;
export const inviteFriendsUrl = `/admin`;
export const campaignsUrl = '/admin';
export const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";
export const inviteFriendsColumns = [
  {
    title: 'Campaign name',
    dataIndex: 'campaignName',
  },
  {
    title: 'Start date / End date',
    render(record: any) {
      return `${formatDate(record.startDate, 'DD.MM.YYYY')}-${formatDate(record.endDate, 'DD.MM.YYYY')}`
    },
  },
  {
    title: 'Invited friend\'s name',
    dataIndex: 'inviteeName',
  },
  {
    title: 'Invited friend’s avatar',
    dataIndex: 'inviteeProfileUrl',
    render(inviteeProfileUrl: string) {
      return <div><Avatar src={inviteeProfileUrl || defaultImage} /></div>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'referralBonusReceived',
    render(referralBonusReceived: boolean) {
      return  referralBonusReceived ? 'Qualified' : 'Registered';
    }
  },
];
export const campaignColumns = [
  {
    title: 'Campaign name',
    dataIndex: 'name',
  },
  {
    title: 'Start date / End date',
    render(record: any) {
      return `${formatDate(record.startDate, 'DD.MM.YYYY')}-${formatDate(record.endDate, 'DD.MM.YYYY')}`
    },
  },
  {
    title: 'Bonus amount',
    dataIndex: 'bonusAmount',
    render(bonusAmount: string) {
      return `${bonusAmount} €`
    },
  },
  {
    title: 'Status',
    dataIndex: 'usageCount',
    render(usageCount: number) {
      return usageCount ? 'Used' : 'Not used';
    }
  }
];
export const Provider: React.FC = () => {
  const params: { id?: string, configIndex?: string, currentPage?:string } = useParams();
  const [visibleModal, setVisible] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [activeTab, setActiveTab] = useState('info');
  const [forceReload, setForceReload] = useState(0);
  let loading = false;
  let data: { provider: any, paymentMethods: any, allLanguages: any, availableWorkingPlaces: any, providerTypes: any} = { provider: {}, paymentMethods: {}, allLanguages: [], availableWorkingPlaces: [], providerTypes: []}
  const details = useProviderDetails(params.id || '', forceReload);
  loading = details.loading;
  data = details.data;
  
  // const profileUrl = data.provider.documents?.find((document: any) => {
  //   return document.type === 'PROFILE';
  // })?.amazonUrl;
  const profileUrl = data?.provider?.documentTypes?.find((documentType: any) => {
    return documentType.type === 'PROFILE';
  })?.documents?.find((doc:any) => doc?.status === 'OK' || doc.status === 'TO_APPROVE' )?.amazonUrl;
  const handleTabChange = (event: any) => {
    setActiveTab(event.target.value);
    setForceReload(Math.random());
  }
  const ordersColumns = [
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
    },
    {
      title: 'Customer Info',
      dataIndex: ['customer'],
      render(customer: any) {
        return <div>{customer && <><Avatar src={customer?.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{customer?.fullName}</span></>}</div>;
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Start Date',
      dataIndex: ['startDate'],
      render(startDate: string) {
        return formatDate(startDate);
      }
    },
    {
      title: 'Duration',
      dataIndex: ['hoursCount'],
      render(hoursCount: number) {
        return getHours(hoursCount);
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Transactions',
      dataIndex: ['id'],
      render(id: any) {
        return (
            <div onClick={() => {
              setVisible(true);
              setTransactions(null);
              authorisedRequest(`/admin/orders/${id}/transactions`)
                  .then((transactions: any) => {
                    setTransactions(transactions)
                  })
            }}>
              <span className='transactions-link'>Transaction History</span>
            </div>
        )
      },
    },
  ];
  const ordersConfigs = React.useMemo(() =>[
    {
      label: 'All',
      params: {
        statuses: 'PENDING,IN_TRANSIT,ARRIVED,IN_PROGRESS,FINISHED,PROVIDER_NOT_FOUND,CANCELLED_BY_CLIENT,CANCELLED_BY_PROVIDER,CANCELLED_BY_ADMIN,PAYMENT_METHOD_NOT_VERIFIED,AUTO_CANCELLED',
        providerId: params.id,
        showTransactions: true
      }
    },
  ], [params.id]);
  const inviteFriendsConfigs = React.useMemo(() =>[
    {
      label: 'All',
      params: {
        url: `/admin/providers/${params.id}/invite-friend-usages`,
      }
    },
  ], [params.id])
  const campaignsConfigs = React.useMemo(() =>[
    {
      label: 'All',
      params: {
        url: `/admin/providers/${params.id}/campaign-usages`,
      }
    },
  ], [params.id])
  let hasRequiredDocs = false;
  // if (data.provider.isCitizen) {
  //   hasRequiredDocs = isContain(REQUIRED_CITIZEN_DOCUMENTS, data.provider.documentTypes);
  // } else if (data.provider?.documents){
  //   hasRequiredDocs = isContain(REQUIRED_NON_CITIZEN_DOCUMENTS, data.provider.documentTypes);
  // }
  if (data.provider.isCitizen) {
    hasRequiredDocs = isContain(REQUIRED_CITIZEN_DOCUMENTS, data.provider.documentTypes);
  } else {
    hasRequiredDocs = isContain(REQUIRED_NON_CITIZEN_DOCUMENTS, data.provider.documentTypes);
  }

  const handleResetTab = () => {
    setActiveTab('info');
    setForceReload(Math.random());
  }

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64, display: 'flex', flexDirection: 'column' }}>
        <Link className='to-back-container' to={`/providers/${params.configIndex}/${params.currentPage}`}>
          <ArrowLeftOutlined />
          <span className='to-back-text'>Back to all providers</span>
        </Link>
        <Space style={{ padding: '22px 0'  }}>
          <Avatar style={{ height: 70, width: 70}}  src={profileUrl || defaultImage} />
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 16}}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <Typography style={{ fontSize: 20, color: '#001629'}}>{data.provider.fullName}</Typography>
              {data.provider.premiumUser && <img src={premiumUser} alt={'Premium'} style={{ marginLeft: 50}} />}
              {data.provider.user?.deactivated ?
              <Tooltip title='Deactivated'>
                <WarningFilled style={styles.warningIcon} />
              </Tooltip> : null}
            </div>
            <Space>
              <Rate disabled allowHalf value={Math.round(data.provider.rating * 2) / 2} />
              {data.provider.rating}
            </Space>
          </Space>
        </Space>
        <Radio.Group style={{ paddingBottom: 16, paddingTop: data.provider.rejectMessage? 30 : 0 }} value={activeTab} onChange={handleTabChange}>
          <Radio.Button value='info'>Provider Info</Radio.Button>
          <Radio.Button value='documents'>Documents</Radio.Button>
          {data.provider.accountStatus === 'ACTIVE' && <Radio.Button value='orders'>Orders</Radio.Button>}
          <Radio.Button value='inviteFriends'>Invited Friends</Radio.Button>
          <Radio.Button value='campaign'>Campaign</Radio.Button>
          <Radio.Button value='workingPlaces'>Working Places</Radio.Button>
        </Radio.Group>
        {activeTab === 'info' && <ProviderDetails data={data} loading={loading} id={params.id!} onChange={() => setForceReload(Math.random())} hasRequiredDocs={hasRequiredDocs} providerTypes={data.providerTypes}/>}
        {activeTab === 'documents' && <ProviderDocuments onUpdate={() => setForceReload(Math.random())} provider={data.provider} providerId={params.id}  hasRequiredDocs={hasRequiredDocs} providerTypes={data.providerTypes}/>}
        {activeTab === 'orders' && <Pager configs={ordersConfigs} url={ordersUrl} columns={ordersColumns} rowClick={() => null} nestedTablePage={'0'} />}
        {activeTab === 'inviteFriends' &&
            <>
              <Card className='inviterInfoCard'>
                <Descriptions title="Invited by" size="small" column={3} className='inviterInfo'>
                  {data?.provider?.referral ?
                  <>
                    <Descriptions.Item contentStyle={styles.inviterInfoItem as CSSProperties} >
                      <Avatar src={data?.provider?.referral?.profileUrl || defaultImage} className='inviterImage' />
                    </Descriptions.Item>
                    <Descriptions.Item contentStyle={styles.inviterInfoItem as CSSProperties}>
                      <span className='title'>Full name</span>
                      <Link className='link'
                            to= {data?.provider?.referral?.userType === 'CLIENT' ?
                                `/clients/${data?.provider?.referral?.id}/0` :
                                `/providers/${data?.provider?.referral?.id}/0/0`}
                            onClick={handleResetTab}
                      >
                        {data?.provider?.referral?.fullName}
                      </Link>
                    </Descriptions.Item>
                    <Descriptions.Item contentStyle={styles.inviterInfoItem as CSSProperties}>
                      <span className='title'>User Type</span>
                      <span style={{ borderBottom: '1px solid' }}>{`${data?.provider?.referral?.userType?.slice(0, 1)}${data?.provider?.referral?.userType?.slice(1).toLowerCase()}`}</span>
                    </Descriptions.Item>
                  </> :
                  <>
                    <Descriptions.Item contentStyle={styles.selfRegisteredInfoItem as CSSProperties} >
                      <Avatar src={selfRegisteredUser} shape='square'  />
                      <span className='selfRegisteredUser'>Self registered user</span>
                    </Descriptions.Item>
                  </>}
                </Descriptions>
              </Card>
              <Pager configs={inviteFriendsConfigs} url={inviteFriendsUrl} columns={inviteFriendsColumns} rowClick={() => null} notSearchable={true} nestedTablePage={'0'} />
            </>}
        {activeTab === 'campaign' && <Pager configs={campaignsConfigs} url={campaignsUrl} columns={campaignColumns} rowClick={() => null} notSearchable={true} nestedTablePage={'0'} />}
        {activeTab === 'workingPlaces' && <ProviderWorkingAreas providerId={params.id as string} />}
      </Layout.Content>
      <TransactionHistory transactions={transactions} visibleModal={visibleModal} setVisible={setVisible} />
    </InnerLayout>
  );
};

const styles = {
  warningIcon: {
    marginLeft: 10,
    color: '#ee7080',
    fontSize: 20
  },
  inviterInfoItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  selfRegisteredInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}
