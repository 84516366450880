import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Typography, Skeleton } from 'antd';
import deleteIcon from '../../icons/deleteIcon.svg';
import Modal from 'antd/lib/modal/Modal';
import { ModalTitle } from './workingPlace-enums';
import { ServiceAreasCitiesModalContent } from './ServiceAreasModalContent';
import { useProviderWorkingPlaces } from './useProviderWorkingPlaces';
import { isUserRoleMarketing } from '../../utils/helpers';
import { MainContext } from '../../App';
import { getProvideWorkingPlacesByPostalCode } from '../../api/providers';
const { Text } = Typography;

interface ProviderWorkingAreasProps {
    providerId: string;
}

export interface PostalCode {
    id: string;
    zipCode: string;
}

export  interface WorkingPlaces {
    city: string;
    countryCode: string;
    postalCodes?: PostalCode[];
    zipCodes?: {id?: string, zipCode: string}[]
}

export  interface Cities {
    city: string;
    cityId: string;
    countryCode: string;
    postalCodes: PostalCode[];
    indexes: string[]
}

export interface DeleteModalContent {
    zipCode: string;
    id: string;
}

const defaultDeleteModalContent: DeleteModalContent = {
    zipCode: '',
    id: '',
}

export const ProviderWorkingAreas: React.FC<ProviderWorkingAreasProps> = ({ providerId  }) => {
    const updateChanges: Function = () => {
        setForceReload(Math.random());
        setModalVisibility(false);
    }
    const { userAuthData } = useContext(MainContext);
    const [workingPlaces, setWorkingPlaces] = useState<WorkingPlaces[]>([]);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalTitle, setModalTitle] = useState<ModalTitle>(ModalTitle.NEW_WORKPLACE);
    const [deleteModalContent, setDeleteModalContent] = useState<DeleteModalContent>(defaultDeleteModalContent);
    const [forceReload, setForceReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const {  deleteProviderWorkPlace, addProviderWorkPlace, getProviderWorkPlaces } = useProviderWorkingPlaces(updateChanges, setLoading);
    const [attachedCities, setAttachedCities] = useState<Cities[]>([]);
    const [cityModalContent, setCityModalContent] =  useState<any>([]);
    const [serviceAreaModalContent, setServiceAreaModalContent] =  useState<Cities[]>([]);
    const [attachArr, setAttachArr] = useState([]);
    const [detachArr, setDetachArr] = useState([]);

    useEffect(()=>{
        getProviderWorkPlaces(providerId)
            .then((resp: WorkingPlaces[]) => {
              setWorkingPlaces(resp);
              Promise.all(resp?.map((item) => getProvideWorkingPlacesByPostalCode(item?.city)))
                    .then((data) =>{
                        const newData = resp?.map((item: any) => {
                            return {
                                ...item, indexes: data?.flat()?.find((el: any) => el?.city === item?.city)?.indexes
                            }

                        })
                        setAttachedCities(newData);
                    });
            })
            .finally(() => setLoading(false))

    }, [forceReload])

    const deleteWorkPlace = (postalCode: PostalCode) => {
       setModalTitle(ModalTitle.DELETE_WORKPLACE);
        setModalVisibility(true);
       setDeleteModalContent(postalCode)
    };

    const addWorkPlace = () => {
        setModalTitle(ModalTitle.NEW_WORKPLACE);
        setModalVisibility(true);
        setServiceAreaModalContent(attachedCities as Cities[])
    };
    const addCity = () => {
        setModalTitle(ModalTitle.NEW_CITY);
        setModalVisibility(true);
        setCityModalContent(attachedCities.map((item: Cities) => ({city: item.city, countryCode: item.countryCode, postalCodes: item.postalCodes, indexes: item.indexes})))
    };

    const handleWorkingPlaceAdd = () => {
        if(attachArr.length) {
            addProviderWorkPlace(providerId as string, attachArr).
                then(() => {
                setModalVisibility(false)
            })
        } else {
            setModalVisibility(false)
        }

        if(detachArr.length) {
            deleteProviderWorkPlace(providerId as string, detachArr).
            then(() => {
                setModalVisibility(false)
            })
        } else {
            setModalVisibility(false)
        }
    }

    const handleWorkingPlaceDelete = () => {
        const serviceAreaIds: string[] = [deleteModalContent.id]
        deleteProviderWorkPlace(providerId as string, serviceAreaIds as string[] )
    }

    const handleCancel = () => {
        setModalVisibility(false);
        setAttachArr([])
        setDetachArr([])
    }

    const getFooterButtons = (modalTitle: ModalTitle) => {
        switch(modalTitle) {
            case ModalTitle.NEW_WORKPLACE:
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleWorkingPlaceAdd} loading={loading} type='primary' key='create'>Save</Button>
                ]
            case ModalTitle.DELETE_WORKPLACE:
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleWorkingPlaceDelete} loading={loading} type='primary' danger key='create'>Delete</Button>
                ]
            case ModalTitle.NEW_CITY:
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleWorkingPlaceAdd} loading={loading} type='primary' key='create'>Save</Button>
                ]
            default:
                return []
        }
    }

    const workingPlacesData: PostalCode[] = [];
    workingPlaces?.forEach(workingPlace => {
        workingPlace?.postalCodes?.forEach((postalCode:PostalCode) => {
            workingPlacesData.push(postalCode)
        })
    })

    return (
        <>
            <Card>
                <Skeleton loading={loading} active>
                    <div style={styles.header}>
                        <Text style={styles.titleText}>Working places</Text>
                        <Button
                            type='primary'
                            onClick={addCity}
                            disabled={isUserRoleMarketing(userAuthData)}
                            style={{marginRight:'10px'}}>
                            Add City
                        </Button>
                        <Button
                            type='primary'
                            onClick={addWorkPlace}
                            disabled={isUserRoleMarketing(userAuthData)}>
                            Add Workplace
                        </Button>
                    </div>
                    {
                        !!workingPlacesData?.length &&
                        <div style={styles.container as React.CSSProperties}>
                            {
                                (workingPlacesData as PostalCode[])?.sort((a:any,b:any) => a.zipCode - b.zipCode)
                                    .map((postalCode:PostalCode) => {
                                        return(
                                            <Button
                                                style={styles.zipCodeItem as React.CSSProperties}
                                                disabled={isUserRoleMarketing(userAuthData)}
                                                key={postalCode.zipCode}
                                            >
                                                <Text style={styles.code}>{postalCode.zipCode}</Text>
                                                <img
                                                    src={deleteIcon}
                                                    alt={'deleteIcon'}
                                                    style={styles.image}
                                                    onClick={()=>deleteWorkPlace(postalCode)}
                                                />
                                            </Button>
                                        )
                                    })
                            }
                        </div>
                    }
                </Skeleton>
            </Card>
            {isModalVisible ? <Modal
                visible={isModalVisible}
                title={modalTitle}
                footer={getFooterButtons(modalTitle)}
                onCancel={handleCancel}
            >
                <ServiceAreasCitiesModalContent
                    modalTitle={modalTitle}
                    setModalContent={modalTitle === ModalTitle.NEW_WORKPLACE ? setServiceAreaModalContent : modalTitle === ModalTitle.DELETE_WORKPLACE ? setDeleteModalContent : setCityModalContent}
                    modalContent = {modalTitle === ModalTitle.NEW_WORKPLACE ? serviceAreaModalContent : modalTitle === ModalTitle.DELETE_WORKPLACE ?  deleteModalContent : cityModalContent}
                    attachArr={attachArr}
                    detachArr={detachArr}
                    setDetachArr={setDetachArr}
                    setAttachArr={setAttachArr}
                />
            </Modal>: null}
        </>
    )
}


const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    titleText: {
        fontWeight: 400,
        fontSize: 20,
        color: '#001629',
        marginRight: 'auto'
    },
    container: {
        display: 'flex',
        gap: 12,
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginRight: 150,

    },
    zipCodeItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
        border: '1px solid #d9d9d9',
        borderRadius: 5,
        width: 112,
        height: 40,
        backgroundColor: '#F0F2F5',
    },
    code: {
        fontWeight: 500,
        fontSize: 16,
        color: '#448EF7',
    },
    image: {
        cursor: 'pointer',
    }
}
