import React, { useState, useContext, useEffect } from 'react';
import { Avatar, Button, Form, Layout, Modal, notification, Select, Switch, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { InnerLayout } from '../../../components/InnerLayout';
import { Pager } from '../../../components/pager/Pager';
import { PriceList, configsForServices, ModalTitle, ErrorCodes, SectionType } from './servicesConfigs';
import { ModalContent } from './ModalContent';
import {
    updateServiceType,
    updateProviderType,
    createServiceType,
    deleteServiceType,
    createProviderType,
    deleteProviderType,
    getProviderTypes,
} from '../../../api/servicePrices';
import { MainContext } from '../../../App';
import { isUserRoleMarketing, isUserSuperAdmin } from '../../../utils/helpers';
const { Text } = Typography;
const { Option } = Select;
export const getUniqueKey = () => {
    return `${new Date().getTime()}`;
}

export interface SubService {
    id?: string;
    serviceName: string;
    translationKey: string;
    uniqueKey?: string;
}

export const defaultSubService: SubService = {
    id: '',
    serviceName: '',
    translationKey: '',
    uniqueKey: getUniqueKey(),
}

export interface Record {
    id?: string;
    translationKey?: string;
    type?: string;
    serviceName?: string;
    serviceImage?: string;
    price?: number | string;
    providerType?: string;
    providerTypes?: string[];
    subServices?: SubService[];
    keyword?: string;
    name?: string;
    providerTypeImage?: string;
    descriptionKeyword?: string;
    flexxiProviderType?: string;
    rcAvailability?: boolean
}

const defaultServiceTypeModalData = {
    id: '',
    translationKey: '',
    serviceName: '',
    serviceImage: '',
    price: '',
    providerType: '',
    providerTypes: [],
    subServices: [defaultSubService],
}

const defaultProviderTypeModalData = {
    id: '',
    price: '',
    keyword: '',
    name: '',
    descriptionKeyword: '',
    providerTypeImage: '',
}

export interface ErrorData {
    priceError: boolean;
    serviceImageError: boolean;
    subServicesError: boolean;
}

const defaultErrorData = {
    priceError: false,
    serviceImageError: false,
    subServicesError: false,
}

export interface LoadingData {
    buttonLoading: boolean;
    imageLoading: boolean;
}

const defaultLoading = {
    buttonLoading: false,
    imageLoading: false,
}

export const Services: React.FC = () => {
    const { userAuthData } = useContext(MainContext);
    const [forceReload, setForceReload] = useState(0);
    const [loading, setLoading] = useState<LoadingData>(defaultLoading);
    const [error, setError] = useState<ErrorData>(defaultErrorData);
    const [toggle, setToggle] = useState(true);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalTitle, setModalTitle] = useState<ModalTitle>(ModalTitle.NEW_SERVICE_TYPE);
    const [configs, setConfigs] = useState(configsForServices);
    const [type, setType] = useState<PriceList>(configs[0].params.type);
    const [careType, setCareType] = useState(SectionType.FlexxiCare);
    const [serviceTypeModalData, setServiceTypeModalData] = useState<Record>(defaultServiceTypeModalData);
    const [providerTypeModalData, setProviderTypeModalData] = useState<Record>(defaultProviderTypeModalData);
    const [editedServiceTypeRecord, setEditedServiceTypeRecord] = useState<Record>(defaultServiceTypeModalData);
    const [editedProviderTypeRecord, setEditedProviderTypeRecord] = useState<Record>(defaultProviderTypeModalData);
    const [imageId, setImageId] = useState<string>('');
    const [providerTypesList, setProviderTypes] = useState<any[]>([]);

    const modalData = type === PriceList.Service ? serviceTypeModalData : providerTypeModalData;
    const setModalData = type === PriceList.Service ? setServiceTypeModalData : setProviderTypeModalData;
    const defaultModalData = type === PriceList.Service ? defaultServiceTypeModalData : defaultProviderTypeModalData;
    const editedRecord = type === PriceList.Service ? editedServiceTypeRecord : editedProviderTypeRecord;
    const setEditedRecord = type === PriceList.Service ? setEditedServiceTypeRecord : setEditedProviderTypeRecord;

    const { buttonLoading } = loading;
    const url = '';

    useEffect(() => {
        if (type === PriceList.Service){
            getProviderTypes()
                .then((data: Record[]) => {
                    const newData = data.map((option: any) => {
                        return { ...option, label: option.name || `${option?.type[0]}${option?.type.slice(1).toLowerCase()}`, value: option.id }
                    })
                    setProviderTypes(newData);
                })
        }
    }, [type])

    const handleSwitchChange =  (id: string) => async(toggle: boolean) => {
        setToggle(toggle)
        setModalData({...modalData, id})
        const response = await updateService(type, { rcAvailability: toggle }, id)
        if (response.status === 'ERROR') {
            return notification.error({
                message: getErrorText(response.result.errorCode),
            })
        }
    }

    const columns = [
        {
            title: 'Keyword',
            dataIndex: 'translationKey',
            render(translationKey: string) {
                return <Text>{translationKey}</Text>
            },

        },
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            render(keyword: string) {
                return <Text>{keyword}</Text>
            },

        },
        {
            title: ' Provider type name',
            render(record: any) {
                return <Text>{record.name || record?.type && `${record?.type[0]}${record?.type?.slice(1).toLowerCase()}`}</Text>
            }
        },
        {
            title: 'Service name',
            dataIndex: 'serviceName',
            render(serviceName: string) {
                return <Text>{serviceName}</Text>
            }
        },
        {
            title: 'Service image',
            dataIndex: 'serviceImage',
            render(serviceImage: string) {
                return <Avatar src={serviceImage} alt={'service image'} shape='square'
                               style={styles.serviceImage as React.CSSProperties} />
            }
        },
        {
            title: 'Provider type image',
            dataIndex: 'providerTypeImage',
            render(providerTypeImage: string) {
                return <Avatar src={providerTypeImage} alt={'provider type image'} shape='square'
                               style={styles.serviceImage as React.CSSProperties} />
            }
        },
        {
            title: 'Service price',
            dataIndex: 'price',
            render(price: number) {
                return <Text>{`${price}€/hour`}</Text>
            }
        },
        {
            title: 'Provider type rate',
            dataIndex: 'price',
            render(price: number) {
                return <Text>{`${price}€/hour`}</Text>
            }
        },
        {
            title: 'Provider type',
            dataIndex: 'providerTypes',
            render(providerTypes: string[]) {
                const selectedProviderNames:string[] = []
                providerTypes?.forEach((id:string) => {
                    const providerType = providerTypesList?.find((item:Record) => item?.id === id)
                    if(providerType){
                        selectedProviderNames.push(providerType.label)
                    }
                })
                return <Text>{selectedProviderNames.join(', ')}</Text>
            }
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id: string, record: Record) => {
                return (
                    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                        {
                            !isUserRoleMarketing(userAuthData) &&
                            <Button
                                onClick={handleEditClick(record)}
                                type='link'
                                icon={<EditOutlined />}
                                disabled={isUserRoleMarketing(userAuthData)}
                            />
                        }
                        {
                            isUserSuperAdmin(userAuthData) &&
                            <Button
                                onClick={handleDeleteClick(id)}
                                className='delete-button'
                                type='link'
                                icon={<DeleteOutlined />}
                                disabled={isUserRoleMarketing(userAuthData)}
                            />
                        }
                        {careType === SectionType.RespiteCare && <Switch onChange={handleSwitchChange(id)} checked={modalData.id === id ? toggle : record.rcAvailability} />}
                    </div>
                )
            }
        },
    ];

    const handleEditClick = (record: Record) => () => {
        setEditedRecord(record);
        type === PriceList.Service ? setModalTitle(ModalTitle.EDIT_SERVICE_TYPE) : setModalTitle(ModalTitle.EDIT_PROVIDER_TYPE)
        setModalData(record);
        setModalVisibility(true);
    }

    const handleDeleteClick = (id: string) => () => {
        type === PriceList.Service ? setModalTitle(ModalTitle.DELETE_SERVICE_TYPE) : setModalTitle(ModalTitle.DELETE_PROVIDER_TYPE)
        setModalData({ ...modalData, id });
        setModalVisibility(true);
    }

    const handleCancel = () => {
        setModalVisibility(false);
        setModalData(defaultModalData);
        setError(defaultErrorData);
    }

    const handleLabelChange = (labelIndex: number) => {
        setCareType(configs[labelIndex].params.flexxiProviderType);
    }

    const handleTypeChange = (type: PriceList) => {
        const newConfigs = configsForServices.map((config: any) => {
            return {
                ...config,
                params: {
                    ...config.params,
                    type,
                    removeIndex: type === PriceList.Service ? [1, 2, 5, 7] : [0, 3, 4, 6, 8],
                    url: type === PriceList.Service ? '/service-types/admin' : '/provider-types'
                }
            }
        });
        setType(type);
        setConfigs(newConfigs);
    }

    const handleAddClick = (modalTitle: ModalTitle) => () => {
        setModalTitle(modalTitle);
        setModalData(defaultModalData);
        setModalVisibility(true);
    }

    const headerComponentsInRight = type === PriceList.Service?
        <Button type='primary' onClick={handleAddClick(ModalTitle.NEW_SERVICE_TYPE)} key='add Service'>+ Add new service</Button>
        :<Button type='primary' onClick={handleAddClick(ModalTitle.NEW_PROVIDER_TYPE)} key='add Provider type' style={{marginLeft: 'auto'}}>+ Add new provider type</Button>

    const headerComponentsInLeft = [
        <Form.Item key='type'>
            <Select value={type} onChange={handleTypeChange}>
                <Option value={PriceList.Service}>Service Type</Option>
                <Option value={PriceList.Provider}>Provider type</Option>
            </Select>
        </Form.Item>
    ]

    const actionButtonDisabled = type === PriceList.Service ? !modalData.translationKey?.trim() || !modalData.serviceName?.trim() ||
        !modalData.serviceImage || !modalData.price || !modalData?.providerTypes?.length : !modalData.keyword?.trim() || !modalData.name?.trim() ||  !modalData.providerTypeImage || !modalData.price || !modalData.descriptionKeyword;

    const updateService = async (priceListType: PriceList, editedPayload: Record, i: string) => {
        const id = modalData.id || i;
        switch (priceListType) {
            case PriceList.Provider:
                return await updateProviderType(id as string, editedPayload);
            case PriceList.Service:
                return await updateServiceType(id as string, editedPayload);
            default:
                return { status: 'ERROR' }
        }
    }

    const checkErrorExist = (price: string, subServices: SubService[]=[]) => {
        let hasError = false;
        if (parseFloat(price as string) > 100 || parseFloat(price as string) < 10) {
            hasError = true;
            setError({ ...error, priceError: true });
        }
        if (subServices) {
            (subServices as SubService[]).forEach(({ translationKey, serviceName }: SubService) => {
                if ((translationKey.trim() && !serviceName.trim()) || (!translationKey.trim() && serviceName.trim())) {
                    hasError = true;
                    setError({ ...error, subServicesError: true });
                }
            })
        }
        return hasError;
    }

    const handleServiceCreate = async () => {
        if (checkErrorExist(modalData.price as string, modalData.subServices as SubService[])) {
            return;
        }
        let subServices = (modalData.subServices as SubService[])?.filter(({ translationKey, serviceName }: SubService) => translationKey.trim() && serviceName.trim())
        subServices = subServices?.length ?
            subServices?.map(({ translationKey, serviceName }: SubService) => {
                return {
                    translationKey: translationKey.trim(),
                    serviceName: serviceName.trim(),
                }
            })
            : [];
        setLoading({...loading, buttonLoading: true});
        const record = type === PriceList.Service ? {
            translationKey: modalData.translationKey?.trim(),
            serviceName: modalData.serviceName?.trim(),
            price: parseFloat(modalData.price as string),
            serviceImage: imageId,
            subServices,
            providerTypes: modalData.providerTypes,
        }: {
            keyword: modalData.keyword,
            name: modalData.name,
            providerTypeImage: imageId,
            price: modalData.price,
            descriptionKeyword: modalData.descriptionKeyword,
        };
        if(careType === SectionType.FlexxiCare){
            (record as Record).flexxiProviderType = 'FLEXXI_CARE'
        } else {
            (record as Record).flexxiProviderType = 'RESPITE_CARE'
        }
        const response = type === PriceList.Service ? await createServiceType(record): await createProviderType(record);
        setLoading({...loading, buttonLoading: false});
        if (response.status === 'ERROR') {
            return notification.error({
                message: getErrorText(response.result.errorCode),
            })
        }
        setModalVisibility(false);
        setModalData(defaultModalData);
        setImageId('');
        setForceReload(Math.random());
    }

    const checkProviderTypesChangesExist = ( providerTypes: string[], editedRecord: Record) => {
        return (providerTypes.length !== editedRecord.providerTypes?.length ||
            providerTypes.some((type: string) => !editedRecord?.providerTypes?.includes(type))
        )
    }

    const checkSubServiseChangesExist = (filteredSubServices: SubService[], editedRecord: Record) => {
        const hasChangesInSubServices = filteredSubServices.some((editedRecordSubServiceItem: SubService) => {
            return (editedRecord.subServices as SubService[]).find((subServicesItem: SubService) => {
                return (
                    editedRecordSubServiceItem.id === subServicesItem.id &&
                    (editedRecordSubServiceItem.translationKey !== subServicesItem.translationKey
                        || editedRecordSubServiceItem.serviceName !== subServicesItem.serviceName)
                )

            })
        }) || (filteredSubServices as SubService[]).length !== editedRecord.subServices?.length;
        return hasChangesInSubServices;
    }

    const handleUpdate = async (editedPayload: Record) => {
        if (!Object.keys(editedPayload).length) {
            handleCancel();
            return;
        }
        setLoading({...loading, buttonLoading: true});
        const response = await updateService(type, editedPayload, '');
        setLoading({...loading, buttonLoading: false});
        if (response.status === 'ERROR') {
            return notification.error({
                message: getErrorText(response.result.errorCode),
            })
        }
        setModalVisibility(false);
        setModalData(defaultModalData);
        setImageId('');
        setForceReload(Math.random());
    }

    const handleProviderTypeUpdate = async () => {
        const { name, keyword, providerTypeImage,  price, descriptionKeyword } = modalData;
        if (checkErrorExist(price as string)) {
            return;
        }
        const editedPayload: Record = {};
        if (editedRecord.price !== parseFloat(price as string)) {
            editedPayload.price = parseFloat(price as string);
        }
        if (editedRecord.name !== name?.trim()) {
            editedPayload.name = name?.trim();
        }
        if (editedRecord.keyword !== keyword?.trim()) {
            editedPayload.keyword = keyword?.trim();
        }
        if (editedRecord.providerTypeImage !== providerTypeImage) {
            editedPayload.providerTypeImage = imageId;
        }
        if (editedRecord.descriptionKeyword !== descriptionKeyword) {
            editedPayload.descriptionKeyword = descriptionKeyword?.trim();
        }
        await handleUpdate(editedPayload);
    }

    const handleServiceUpdate = async () => {
        const { translationKey, serviceImage, serviceName, price, providerTypes, subServices } = modalData;

        if (checkErrorExist(price as string, subServices as SubService[])) {
            return;
        }
        const editedPayload: Record = {};
        if (editedRecord.price !== parseFloat(price as string)) {
            editedPayload.price = parseFloat(price as string);
        }
        if (editedRecord.translationKey !== translationKey?.trim()) {
            editedPayload.translationKey = translationKey?.trim();
        }
        if (editedRecord.serviceName !== serviceName?.trim()) {
            editedPayload.serviceName = serviceName?.trim();
        }
        if (editedRecord.serviceImage !== serviceImage) {
            editedPayload.serviceImage = imageId;
        }
        if (checkProviderTypesChangesExist(providerTypes as string[], editedRecord)) {
            editedPayload.providerTypes = providerTypes;
        }
        const filteredSubServices = (subServices as SubService[]).filter(({ translationKey, serviceName }: SubService) => translationKey.trim() && serviceName.trim())
            .map((i: SubService) =>{
                return{ ...i, translationKey: i.translationKey?.trim(), serviceName: i.serviceName?.trim() }
            });

        const hasChangesInSubServices = checkSubServiseChangesExist(filteredSubServices as SubService[], editedRecord)
        if (hasChangesInSubServices && filteredSubServices.length) {
            editedPayload.subServices = (filteredSubServices as SubService[]).map(({ id, translationKey, serviceName }: SubService) => {
                return !id ?
                    {
                        translationKey,
                        serviceName
                    } :
                    {
                        id,
                        translationKey,
                        serviceName
                    }
            });
        } else if (hasChangesInSubServices) {
            editedPayload.subServices = [];
        }
        await handleUpdate(editedPayload);
    }

    const handleServiceDelete = async () => {
        const { id } = modalData;
        setLoading({...loading, buttonLoading: true});
        const response = type === PriceList.Service  ? await deleteServiceType(id as string) : await deleteProviderType(id as string);
        setLoading({...loading, buttonLoading: false});
        if (response?.status === 'ERROR') {
            return notification.error({
                message: getErrorText(response.result.errorCode),
            })
        }
        setModalVisibility(false);
        setModalData(defaultModalData);
        setForceReload(Math.random())
    }

    const getFooterButtons = (modalTitle: ModalTitle) => {
        switch (modalTitle) {
            case ModalTitle.NEW_SERVICE_TYPE:
            case ModalTitle.NEW_PROVIDER_TYPE:
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button disabled={actionButtonDisabled} onClick={handleServiceCreate} loading={buttonLoading}
                            type='primary' key='create'>Create</Button>
                ]
            case ModalTitle.EDIT_SERVICE_TYPE:
            case ModalTitle.EDIT_PROVIDER_TYPE:
                return [
                    <Button onClick={handleCancel} key="cancel">Cancel</Button>,
                    <Button disabled={actionButtonDisabled} onClick={type === PriceList.Service ? handleServiceUpdate : handleProviderTypeUpdate} loading={buttonLoading}
                            type='primary'
                            key='save'>Save</Button>
                ]
            case ModalTitle.DELETE_SERVICE_TYPE:
            case ModalTitle.DELETE_PROVIDER_TYPE:
                return [
                    <Button onClick={handleCancel} key='cancel'>Cancel</Button>,
                    <Button onClick={handleServiceDelete} loading={buttonLoading} type='primary' danger key='yes'>Yes</Button>
                ]
            default:
                return []
        }
    }

    return (
        <InnerLayout>
            <Layout.Content className='site-layout' style={styles.Layout}>
                <Pager
                    url={url}
                    columns={columns}
                    configs={configs}
                    rowClick={() => null}
                    forceReload={forceReload}
                    onLabelChange={handleLabelChange}
                    headerComponentsInRight={isUserSuperAdmin(userAuthData) ? headerComponentsInRight : null}
                    headerComponentsInLeft={headerComponentsInLeft}
                    notSearchable={type !== PriceList.Service}
                />
            </Layout.Content>
            <Modal
                visible={isModalVisible}
                title={modalTitle}
                footer={getFooterButtons(modalTitle)}
                onCancel={handleCancel}
            >
                <ModalContent
                    modalData={modalData}
                    setModalData={setModalData}
                    modalTitle={modalTitle}
                    error={error}
                    setError={setError}
                    type={type}
                    setImageId={setImageId}
                    loading={loading}
                    setLoading={setLoading}
                    providerTypesList={providerTypesList}
                />
            </Modal>
        </InnerLayout>
    )
}

export const getErrorText = (errorCode: string) => {
    switch(errorCode) {
        case 'SERVICE_TYPE_ALREADY_EXISTS':
            return ErrorCodes.SERVICE_TYPE_ALREADY_EXISTS;
        case 'SERVICE_TYPE_NOT_FOUND':
            return ErrorCodes.SERVICE_TYPE_NOT_FOUND
        case 'SERVICE_TYPE_MIN_PRICE_VIOLATION':
            return ErrorCodes.SERVICE_TYPE_MIN_PRICE_VIOLATION
        case 'PROVIDER_TYPE_NOT_FOUND':
            return ErrorCodes.PROVIDER_TYPE_NOT_FOUND
        case 'PROVIDER_TYPE_MIN_PRICE_VIOLATION':
            return ErrorCodes.PROVIDER_TYPE_MIN_PRICE_VIOLATION
        case 'FILE_INVALID_ERROR':
            return ErrorCodes.FILE_INVALID_ERROR
        case 'FILE_UPLOAD_ERROR':
            return ErrorCodes.FILE_UPLOAD_ERROR
        default:
            return ErrorCodes.DEFAULT_ERROR
    }
}

const styles = {
    Layout: {
        padding: '20px 30px',
        marginTop: 64,
    },
    serviceImage: {
        width: 70,
        height: 39,
        objectFit: 'contain',
        border: 'solid 1px #d9d9d9',
    }
}
