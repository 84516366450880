import React, { useState } from 'react';
import { Layout, Avatar } from 'antd';

import { InnerLayout } from '../../components/InnerLayout';
import { Pager } from '../../components/pager/Pager';
import { OrderDetails } from '../../components/orderDetails/OrderDetails';
import { getHours, formatDate } from '../../utils/helpers';

export const Orders = ({ configs }: { configs?: Array<any> }) => {
  const [forceReload, setForceReload] = useState(0);
  const [activeOrderId, setActiveOrderId] = useState('');

  const url = `/admin/orders`;
  const defaultImage = "https://www.w3schools.com/howto/img_avatar.png";

  const columns = [
    {
      title: 'Order Id',
      dataIndex: 'orderNumber',
    },
    {
      title: 'Customer Info',
      dataIndex: ['customer'],
      render(customer: any) {
        return <div><Avatar src={customer.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{customer.fullName}</span></div>;
      },
    },
    {
      title: 'Provider Info',
      dataIndex: ['provider'],
      render(provider: any) {
      return provider ? <div>{provider && <><Avatar src={provider?.profileUrl || defaultImage} /><span style={{ marginLeft: 8 }}>{provider?.fullName}</span></>}</div> : 'N/A';
      },
    },
    {
      title: 'Working Area',
      dataIndex: ['orderAddress'],
      render(orderAddress: any) {
        if (!orderAddress) {
          return 'N/A';
        }
        const area = `${orderAddress.country} ${orderAddress.city}`;
        return area;
      },
    },
    {
      title: 'Start Date',
      dataIndex: ['startDate'],
      render(startDate: string, data: any) {
        return formatDate(startDate || data.earlyStartDate, 'DD-MM-YYYY HH:mm');
      }
    },
    {
      title: 'Duration',
      dataIndex: ['hoursCount'],
      sorter: {
        multiple: 1
      },
      key: 'hoursCount',
      render(hoursCount: number) {
        return getHours(hoursCount);
      }
    },
    {
      title: 'Amount',
      sorter: {
        multiple: 1
      },
      key: 'amount',
      dataIndex: ['orderAmount'],
      render(orderAmount: string) {
        return orderAmount ? `€${orderAmount}` : 'N/A';
      }
    },
    {
      title: 'Order amount',
      sorter: {
        multiple: 1
      },
      key: 'orderAmount',
      dataIndex: ['orderAmount'],
      render(orderAmount: string) {
        return orderAmount ? `€${orderAmount}` : 'N/A';
      }
    },
    {
      title: 'Paid amount',
      sorter: {
        multiple: 1
      },
      key: 'paidAmount',
      dataIndex: ['paidAmount'],
      render(paidAmount: string) {
        return paidAmount ? `€${paidAmount}` : 'N/A';
      }
    },
  ];

  return (
    <InnerLayout>
      <Layout.Content className="site-layout" style={{ padding: '20px 30px', marginTop: 64 }}>
        <Pager url={url} columns={columns} configs={configs} rowClick={(id: string) => setActiveOrderId(id)} forceReload={forceReload} />
      </Layout.Content>
      {activeOrderId &&
        <Layout.Sider
          width={400}
          style={{
            padding: '84px 30px 84px 0',
            backgroundColor: 'transparent',
            overflow: 'auto',
            height: '100vh',
          }}
        >
          {activeOrderId && <OrderDetails id={activeOrderId} onChange={() => { setActiveOrderId(''); setForceReload(Math.random()); }} />}
        </Layout.Sider>}
    </InnerLayout>
  )
}
